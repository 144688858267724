<template>
    <div class="edit-project content">
        <main>
            <section class="header" id="edit-project">
                <h2 class="title text-right">
                    {{ $t("portfolio.edit.catch") }}
                </h2>
                <p class="text-right" v-html="$t('portfolio.edit.text')"></p>
                <div class="container back-link">
                    <Link
                        :back="true"
                        path="/portfolio"
                        :title="$t('nav.return')"
                    />
                </div>
                <div class="edit-project">
                    <EditProjectForm
                        @success="redirect"
                        @failed="$emit('done')"
                        @submit="loading"
                        :category="project.category"
                        :project="project"
                    />
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import EditProjectForm from "@/components/form/EditProjectForm";
import Link from "@/components/Link";

export default {
    components: {
        EditProjectForm,
        Link
    },
    props: {
        project: Object
    },
    data: function() {
        return {};
    },
    methods: {
        loading() {
            this.$emit("loading", this.$t("portfolio.edit.loading"));
        },
        redirect() {
            this.$emit("done");
            this.$router.push("/portfolio");
        }
    },
    metaInfo() {
        return {
            title: `${
                this.$root.$i18n.locale === "en"
                    ? "Edit project"
                    : "Project aanpassen"
            }`,
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content: "Edit project - Renders by Julie"
                },
                {
                    name: "description",
                    content: "Aanpassen van een project in portfolio"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "robots",
                    content: "noindex, nofollow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                }
            ]
        };
    }
    // metaInfo() {
    //     return {
    //         // SET NOFOLLOW NO INDEX No robots
    //         title: `${
    //             this.$root.$i18n.locale === "nl"
    //                 ? "Project bewerken"
    //                 : "Edit project"
    //         }`,
    //         htmlAttrs: {
    //             lang: this.$root.$i18n.locale
    //         }
    //     };
    // }
};
</script>
<style lang="scss">
#edit-project {
    @media (max-width: 700px) {
        * {
            text-align: left;
        }
    }
}
</style>
