<template>
    <div>
        <form
            @submit.prevent="validateForm($event)"
            enctype="multipart/form-data"
        >
            <p v-if="error" class="invalid red">{{ error }}</p>
            <div class="form-element">
                <Input
                    :value="project.title"
                    name="title"
                    :label="$t('portfolio.add.title')"
                    :placeholder="$t('portfolio.add.title-placeholder')"
                    :required="true"
                    :class="{ invalid: errors.title }"
                    :remark="
                        errors.title ? { message: $t('error.title') } : null
                    "
                />
            </div>

            <div class="form-element">
                <Input
                    name="designer"
                    :value="project.designer"
                    :label="$t('portfolio.add.designer')"
                    :placeholder="$t('portfolio.add.designer-placeholder')"
                />
            </div>

            <div class="form-element">
                <Input
                    name="renderer"
                    :value="project.renderer"
                    :label="$t('portfolio.add.renderer')"
                    :placeholder="$t('portfolio.add.renderer-placeholder')"
                />
            </div>

            <div class="form-element">
                <Input
                    :value="project.location"
                    name="location"
                    :label="$t('portfolio.add.location')"
                    :placeholder="$t('portfolio.add.location-placeholder')"
                    :required="true"
                    :class="{ invalid: errors.location }"
                    :remark="
                        errors.location
                            ? { message: $t('error.location') }
                            : null
                    "
                />
            </div>

            <div class="form-element">
                <SelectInput
                    name="category"
                    :label="$t('portfolio.add.category')"
                    :required="true"
                    :placeholder="$t('portfolio.add.category-placeholder')"
                    :class="{ invalid: errors.category }"
                    :selected="category ? category : null"
                    :remark="
                        errors.category
                            ? { message: $t('error.category') }
                            : null
                    "
                    :data="{
                        interior: $t('portfolio.interior'),
                        exterior: $t('portfolio.exterior'),
                        floorplan: $t('portfolio.floorplan')
                    }"
                />
            </div>

            <div class="form-element date-input">
                <DateInput
                    :value="project.date"
                    :checked="project.ongoing === 1 ? true : false"
                    name="date"
                    :label="$t('portfolio.add.date')"
                    :placeholder="$t('portfolio.add.date-placeholder')"
                    :required="true"
                    :class="{ invalid: errors.date }"
                    :remark="errors.date ? { message: $t('error.date') } : null"
                    :ongoing="true"
                    @date-selected="setDate"
                    @ongoing-selected="setOngoing"
                />
            </div>

            <div class="form-element">
                <CheckboxInput
                    name="visible"
                    :label="$t('portfolio.add.visible')"
                    :checked="project.visible === 1 ? true : false"
                    @change="setVisible"
                />
            </div>

            <div class="form-element">
                <FileInput
                    :edit="true"
                    @changedInput="
                        errors.file = false;
                        errors.file_type = false;
                        errors.file_size = false;
                    "
                    @errorType="errors.file_type = true"
                    @errorSize="errors.file_size = true"
                    @filesChanged="fileChanged"
                    @thumbSelected="setThumbnail"
                    @addedThumbSelected="setThumbnail($event.name)"
                    name="images"
                    :label="$t('portfolio.add.file')"
                    :placeholder="$t('portfolio.add.file-placeholder')"
                    :required="true"
                    :class="{
                        invalid:
                            errors.file ||
                            errors.file_type ||
                            errors.file_size ||
                            errors.thumbnail
                    }"
                    :remark="
                        errors.file
                            ? { message: $t('error.file') }
                            : errors.file_type
                            ? { message: $t('error.file-type') }
                            : errors.file_size
                            ? { message: $t('error.file-size') }
                            : errors.thumbnail
                            ? { message: $t('error.file-thumbnail') }
                            : null
                    "
                />
                <div id="thumbnail-grid">
                    <div
                        :class="{
                            'thumbnail-container': true,
                            'selected-thumbnail': project.thumbnail == image
                        }"
                        v-for="(image, ind) in images"
                        :key="ind"
                        :id="image"
                    >
                        <div
                            class="thumbnail-remove icon cancel"
                            @click="removeExistingImage(image)"
                        ></div>
                        <div class="inner-thumbnail-container">
                            <img
                                @click="setThumbnail(image)"
                                class="thumbnail"
                                title="Project image"
                                :src="
                                    `${$apiroute}/images/${project.id}-${project.key}/${image}`
                                "
                                alt="Thumbnail of project image"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-element hidden">
                <input
                    type="text"
                    v-model="project.thumbnail"
                    name="thumbnail"
                    id="thumbnail"
                />
            </div>

            <div class="bottom right">
                <div class="container back-link">
                    <Link
                        :back="true"
                        path="/portfolio"
                        :title="$t('nav.return')"
                    />
                </div>
                <button type="submit">
                    <span>
                        {{ $t("portfolio.edit.submit") }}
                    </span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import Link from "@/components/Link";
import Input from "@/components/form/Input";
import SelectInput from "@/components/form/SelectInput";
import DateInput from "@/components/form/DateInput";
import FileInput from "@/components/form/FileInput";
import CheckboxInput from "@/components/form/CheckboxInput";
import axios from "axios";
import { getCookie } from "@/helpers";

export default {
    components: {
        Link,
        Input,
        SelectInput,
        DateInput,
        FileInput,
        CheckboxInput
    },
    props: {
        category: String,
        project: Object
    },
    data: function() {
        return {
            date: this.project.date,
            ongoing: this.project.ongoing,
            visible: this.project.visible,
            images: this.project.images.split(","),
            deletedImages: [],
            files: [],
            thumbnail: this.project.thumbnail,
            error: null,
            errors: {
                title: false,
                date: false,
                location: false,
                category: false,
                file: false,
                file_type: false,
                file_size: false
            }
        };
    },
    methods: {
        validateForm(event) {
            this.errors = {
                title: false,
                date: false,
                location: false,
                category: false,
                file: false,
                file_type: false,
                file_size: false,
                thumbnail: false
            };
            let title = document.querySelector("input[name=title]").value;
            let designer = document.querySelector("input[name=designer]").value;
            let renderer = document.querySelector("input[name=renderer]").value;
            let location = document.querySelector("input[name=location]").value;
            let category = document.querySelector("select[name=category]")
                .value;

            if (!title) {
                this.errors.title = true;
            }
            if (!this.date) {
                this.errors.date = true;
            }
            if (!location) {
                this.errors.location = true;
            }
            if (!category) {
                this.errors.category = true;
            }
            if (!this.images.length) {
                this.errors.file = true;
            }
            if (!this.thumbnail) {
                this.errors.thumbnail = true;
            }

            // Check if errors are present
            if (Object.values(this.errors).filter(x => x === true).length) {
                document.getElementById("edit-project").scrollIntoView();
            } else {
                let project = new FormData();
                project.append("_method", "post");
                for (let i = 0; i < this.files.length; i++) {
                    project.append(
                        `images[${i}]`,
                        this.files[i],
                        this.files[i].name
                    );
                }

                project.append("key", title.toLowerCase().replaceAll(" ", "-"));
                project.append("title", title);
                project.append("location", location);
                project.append("date", this.date);
                project.append("category", category);
                project.append("designer", designer);
                project.append("renderer", renderer);
                project.append("ongoing", this.ongoing);
                project.append("visible", this.visible);
                project.append("thumbnail", this.thumbnail);
                project.append("delete", this.deletedImages.join(","));

                this.edit(project);
            }
        },
        async edit(project) {
            // console.log("submit", project);
            this.$emit("submit");

            try {
                await axios
                    .post(
                        `${this.$apiroute}/projects/update/${this.project.id}`,
                        project,
                        {
                            headers: {
                                Authorization: `Bearer ${getCookie("token")}`,
                                "Content-Type": "multipart/form-data"
                            }
                        }
                    )
                    .then(response => {
                        // console.log(response);
                        // Clear stored projects
                        this.$store.dispatch("getProjects");
                        this.$emit("success");
                    })
                    .catch(error => {
                        // console.log(error);
                        // console.log(error.response);
                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                        this.error = this.$t("error.something-wrong");
                        document
                            .getElementById("edit-project")
                            .scrollIntoView();
                        this.$emit("failed");
                    });
            } catch (error) {
                console.log(error);
                document.getElementById("edit-project").scrollIntoView();
                this.error = this.$t("error.something-wrong");
                this.$emit("failed");
            }
        },
        setDate(date) {
            this.errors.date = false;
            this.date = new Date(date).toISOString();
        },
        setOngoing(ongoing) {
            this.ongoing = ongoing;
        },
        setVisible(visible) {
            this.visible = visible;
        },
        fileChanged(images) {
            this.files = images;
            setTimeout(() => {
                this.setThumbnail(this.images[0]);
            }, 1000);
        },
        setThumbnail(image) {
            let thumbs = document.getElementsByClassName("thumbnail-container");

            if (thumbs.length) {
                for (let i = 0; i < thumbs.length; i++) {
                    thumbs[i].classList.remove("selected-thumbnail");
                }
            }

            document.getElementById(image).classList.add("selected-thumbnail");
            this.thumbnail = image;
        },
        removeExistingImage(file) {
            if (confirm(this.$t("portfolio.add.file-remove"))) {
                // Remove from data
                this.images = this.images.filter(img => img !== file);
                // Set to delete file
                this.deletedImages.push(file);
                if (this.thumbnail == file) {
                    this.setThumbnail(this.images[0]);
                }
            }
        }
    }
};
</script>

<style lang="scss">
form {
    .bottom {
        margin-top: 5rem;

        @media (max-width: 700px) {
            margin-top: 3.5rem;
            flex-direction: column;

            button {
                order: -1;
                width: 100%;
                margin-bottom: 2rem;
            }
        }
    }

    .invalid {
        input,
        .dropbox,
        select {
            border-bottom: 3px solid $red !important;
        }
    }
}
</style>
